<template>
  <div id="success-detail">
    <van-nav-bar title="订单详情" left-arrow @click-left="goLastPage" :border="false" />
    <ProductInfor buttonText="确认完工" @buttonClick="goSubPage('affirm-finished',$route.params.orderId)" :needButton="false" />
  </div>
</template>
<script>
import ProductInfor from "@/components/order/ProductInfor.vue";
export default {
  components: {
    ProductInfor,
  },
};
</script>
<style>
</style>